<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import DatePicker from "vue2-datepicker";
//import Multiselect from "vue-multiselect";
import Timeline from "@/components/dashboard/personalizations/timeline"
import FilterC from "@/components/dashboard/personalizations/filter"
import helpers from '../../../helpers'
import Drawer from "vue-simple-drawer";

import {
  analyticsMethods,
  contentMethods,
} from "@/state/helpers";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Personalizations Analytics",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    DatePicker,
    //Multiselect,
    Timeline,
    Drawer,
    FilterC
  },
  data() {
    return {
      title: this.$t('kpi.title'),
      items: [],
      
      showModal: false,
      isLoading: false,
      fullPage: true,
      canCancel: false,
      query: '',
      user: JSON.parse(localStorage.getItem("auth.currentUser")).user,
      dateFilter: null,
      disabledBefore: new Date().setDate(new Date().getDate() - 90),
      shortcuts: [
        {
          text: this.$t('kpi.filter_date_today'),
          onClick() {
            const start = new Date();
            const end = new Date();
            
            end.setTime(end.getTime() + 0 * 24 * 3600 * 1000);
            const date = [start, end];
            
            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_yesterday'),
          onClick() {
            
            const start = new Date();
            const end = new Date();
            
            start.setTime(start.getTime() -1 * 24 * 3600 * 1000);
            const date = [start, end];
            
            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_this_week'),
          onClick() {
            
            var curr = new Date; // get current date
            var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
            var last = first + 6; // last day is the first day + 6

            var firstday = new Date(curr.setDate(first));
            var lastday = new Date(curr.setDate(last));
            
            const date = [firstday, lastday];

            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_this_month'),
          onClick() {
            var today = new Date; // get current date
            const firstday = new Date(today.getFullYear(), today.getMonth(), 1);
            //const lastday = new Date(today.getFullYear(), today.getMonth() + 1, 0);

            const date = [firstday, today];
            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_this_last_week'),
          onClick() {
            const today = new Date();
            const lastWeek = new Date(today);
            lastWeek.setDate(today.getDate() - 7); // Resta 7 días para obtener la semana pasada

            // Obtén el primer día de la semana pasada (domingo)
            const firstDay = new Date(lastWeek);
            firstDay.setDate(lastWeek.getDate() - lastWeek.getDay());

            // Obtén el último día de la semana pasada (sábado)
            const lastDay = new Date(lastWeek);
            lastDay.setDate(lastWeek.getDate() - lastWeek.getDay() + 6);

            const date = [firstDay, lastDay];

            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_this_last_month'),
          onClick() {
            const today = new Date();
  
            // Calculate the first day of the last month
            const firstDay = new Date(today.getFullYear(), today.getMonth() - 1, 1);
  
            // Calculate the last day of the last month
            const lastDay = new Date(today.getFullYear(), today.getMonth(), 0);
            const date = [firstDay, lastDay];

            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_this_last_7_days'),
          onClick() {
            const today = new Date();
            const lastDay = new Date(today); // Last day is today
            const firstDay = new Date(today);
            firstDay.setDate(today.getDate() - 6); // First day is 7 days ago

            const date = [firstDay, lastDay];
            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_this_last_30_days'),
          onClick() {
            
            const today = new Date();
            const lastDay = new Date(today); // Last day is today
            const firstDay = new Date(today);
            firstDay.setDate(today.getDate() - 29); // First day is 30 days ago

            const date = [firstDay, lastDay];
            return date;
          },
        },
      ],

      isLocal: window.config.local ? true : false,

      goals: [
        { _id: 'all',name : this.$t('personal_analyze.goal_all')},
        { _id: 'purchase',name : this.$t('goals.purchase')},
        { _id: 'cart',name : this.$t('goals.cart')},
        { _id: 'like',name : this.$t('goals.like')},
        { _id: 'view',name : this.$t('goals.view')},
      ],
      auxGoal: { _id: 'all',name : this.$t('personal_analyze.goal_all')},
      sessions: '-',
      impressions: '-',
      conversions: '-',
      revenue: '-',
      conversion_rate: '-',
      personalizationId: null,
      purchase_rate: '-',
      total_revenue:'-',
      openRightDrawer: false,
      filters: [],
      purchased_sessions:'-',
      personalAnalytics: [],
      customers: '-',
      revenue_customer: '-',
      aov: '-',
      previousAnalytics: [],

      previous_aov: '-',
      previous_conversion_rate: '-',
      previous_conversions: '-',
      previous_cr: '-',
      previous_ctr: '-',
      previous_customers: '-',
      previous_impressions: '-',
      previous_purchase_rate: '-',
      previous_purchased_sessions: '-',
      previous_revenue: '-',
      previous_revenue_customer: '-',
      previous_sessions: '-',
      previous_total_revenue: '-',
      
      periodLength: 0
    };
  },
  mounted() {
    this.personalizationId = this.$route.query?.personalization;
    const today = new Date();
    const lastDay = new Date(today); 
    const firstDay = new Date(today);
    firstDay.setDate(today.getDate() - 29); 
    
    this.dateFilter = [firstDay, lastDay]; 

    if(this.personalizationId){
      this.getContent(this.personalizationId).then((personalization)=>{
        if(personalization?.data){
          this.filters.push({
            key: 'content',
            value: personalization.data._id,
            badge: `${this.$t('filter.personalization')}: ${personalization.data.name}`
          })
        }
        const nextDay = new Date(this.dateFilter[1]);
        nextDay.setDate(this.dateFilter[1].getDate() + 1)

        let query = `project=${localStorage.getItem('current_project')}`
        query+= `&dateFrom=${this.formatDate(this.dateFilter[0])}&dateTo=${this.formatDate(nextDay)}`;

        this.applyFilter(query)
      })
    }
  },
  methods:{
    ...analyticsMethods,
    ...helpers,
    ...contentMethods,
    
    rightDrawer(){
      this.openRightDrawer = false;
    },
    
    onGoalChanged(){
      const nextDay = new Date(this.dateFilter[1]);
      nextDay.setDate(this.dateFilter[1].getDate() + 1)

      let query = `project=${localStorage.getItem('current_project')}`
      query+= `&dateFrom=${this.formatDate(this.dateFilter[0])}&dateTo=${this.formatDate(nextDay)}`;  

      if(this.auxGoal && this.auxGoal._id && this.auxGoal._id != 'all'){
        query+=`&goal=${this.auxGoal._id}`
      }
      this.applyFilter(query)
    },

    removeAllFilter(){
      this.$refs.filterRef.removeAll();
    },
    applyFilter(query){

      const loader = this.$loading.show();

      if(this.filters.length > 0){
        query+= this.getCustomFilterQuery();
      }

      this.query = query;
      
      this.getPersonalizationsCustomers(query).then((resCustomer)=>{
        this.customers = resCustomer.data?.length > 0 ? resCustomer.data[0].count : 0;
      });
      
      this.getPersonalizationIndicators(query).then((res)=>{
        this.personalAnalytics = res.data;

        this.sessions = res.data.length > 0 ? this.getSummaryByKey('personalized_sessions', this.personalAnalytics): 0;
        this.impressions = res.data.length > 0 ? this.getSummaryByKey('impressions', this.personalAnalytics): 0;
        this.conversions = res.data.length > 0 ? this.getSummaryByKey('conversions', this.personalAnalytics): 0;
        this.revenue = res.data.length > 0 ? this.getSummaryByKey('associated_revenue', this.personalAnalytics): 0;
        
        this.conversion_rate = this.sessions == '-' ? `-` : this.sessions == 0 ? `0%` : `${this.parsePercentage(this.conversions/this.sessions * 100)}%`
        this.total_revenue = res.data.length > 0 ? this.getSummaryByKey('total_revenue', this.personalAnalytics): 0;
        this.purchased_sessions = res.data.length > 0 ? this.getSummaryByKey('purchased_sessions', this.personalAnalytics): 0;
        this.purchase_rate =  this.sessions == '-' ? `-` : this.sessions == 0 ? `0%` : `${this.parsePercentage(this.purchased_sessions/this.sessions * 100)}%`
        
        this.revenue_customer = this.customers == 0 ? 0 : this.revenue/this.customers;

        const total_aov = res.data.length > 0 ? this.getSummaryByKey('aov', this.personalAnalytics): '0';
        const days = this.personalAnalytics.filter(p=>p.aov > 0).length;
        this.aov =  days == 0 ? 0 : total_aov/days;

        if(this.filters.filter(f=>f.key=='compare').length>0){
          this.getPreviouspersonalizationsIndicators();
        }
    }).catch((ex)=>{
      // eslint-disable-next-line no-console
      console.log(ex);
    }).finally(()=>{
      loader.hide()
      // eslint-disable-next-line no-console
      console.log('end');
    })
    },

    applyCustomFilter(){
      const nextDay = new Date(this.dateFilter[1]);
      nextDay.setDate(this.dateFilter[1].getDate() + 1)

      let query = `project=${localStorage.getItem('current_project')}`
      query+= `&dateFrom=${this.formatDate(this.dateFilter[0])}&dateTo=${this.formatDate(nextDay)}`;
      
      const fs = this.$refs.filterRef.getAllFilters();

      if(this.filters.filter(f=>f.key=="compare").length > 0){
        fs.push({
          badge: `${this.$t('common.compare_badge')}`,
          key: 'compare'
        })
      }

      this.filters = fs;
      
      this.applyFilter(query)
      this.openRightDrawer = false;
    },
    onFilterClicked(){
      this.openRightDrawer=true; 
      setTimeout(()=>{
        this.$refs.filterRef.setAllFilter(this.filters)
      },1000)
    },
    getCustomFilterQuery(){
      let q = '';
      if(this.filters.length>0){
        this.filters.forEach((f)=>{
          if(f.key!='compare'){
            q+= `&key=${f.key}&value=${f.value}`
          }
        });
      }

      return q;
    },
    onRemoveFilter(filter){
      this.filters = this.filters.filter(f=> f.key != filter.key);
      
      if(this.filters.length == 0)
        this.personalizationId = null;

      if(filter.key== 'compare'){
        this.previousAnalytics = [];
      }

      const nextDay = new Date(this.dateFilter[1]);
      nextDay.setDate(this.dateFilter[1].getDate() + 1)

      let query = `project=${localStorage.getItem('current_project')}`
      query+= `&dateFrom=${this.formatDate(this.dateFilter[0])}&dateTo=${this.formatDate(nextDay)}`;

      this.applyFilter(query)
      
      },
      onComparePreviousPeriod(){
        if(this.filters.filter(f=>f.key=='compare').length == 0){
            this.filters.push({
              badge: `${this.$t('common.compare_badge')}`,
              key: 'compare'
            })
            this.applyFilter(this.query);
        }
        
        this.$refs.actionsDropdown.hide();
      },

      getPreviouspersonalizationsIndicators (){

        const dates = this.getPreviousPeriod(this.dateFilter[0],this.dateFilter[1]);
        const nextDay = new Date(dates.previousDateTo);
        nextDay.setDate(dates.previousDateTo.getDate() + 1)

        this.previousDates = dates;

        let queryPrevious = `project=${localStorage.getItem('current_project')}`
        queryPrevious+= `&dateFrom=${this.formatDate(dates.previousDateFrom)}&dateTo=${this.formatDate(nextDay)}`;  

        queryPrevious+= this.getCustomFilterQuery();

        
          this.getPersonalizationsCustomers(queryPrevious).then((resprev)=>{
            const previous_customers = resprev.data?.length > 0 ? resprev.data[0].count : 0;
            this.previous_customers = this.calculatePercentageChange(this.customers, previous_customers) ;
          });
        
        
        this.getPersonalizationIndicators(queryPrevious).then((res)=>{

          this.previousAnalytics = res.data;
          
          const prev_sessions = res.data.length > 0 ? this.getSummaryByKey('personalized_sessions', this.previousAnalytics): 0;
          this.previous_sessions = this.calculatePercentageChange(this.sessions, prev_sessions) ;

          const prev_customers = res.data.length > 0 ? this.getSummaryByKey('customers', this.previousAnalytics): 0;
          this.previous_customers = this.calculatePercentageChange(this.customers, prev_customers) ;
          
          const prev_impressions = res.data.length > 0 ? this.getSummaryByKey('impressions', this.previousAnalytics): 0;
          this.previous_impressions = this.calculatePercentageChange(this.impressions, prev_impressions) ;

          const prev_conversions = res.data.length > 0 ? this.getSummaryByKey('conversions', this.previousAnalytics): 0;
          const prev_cr = prev_sessions == 0? 0 : prev_conversions/prev_sessions * 100 ;

          const actual_cr = this.sessions == 0 ? 0 : (this.conversions/this.sessions * 100 );

          this.previous_conversion_rate = prev_cr == 0 && actual_cr >0 ?100 : prev_cr==0 ? 0 : this.calculatePercentageChange(actual_cr, prev_cr) ;

          const prev_revenue = res.data.length > 0 ? this.getSummaryByKey('associated_revenue', this.previousAnalytics): 0;
          this.previous_revenue = this.calculatePercentageChange(this.revenue, prev_revenue) ;

          let prev_aov =  res.data.length > 0 ? this.getSummaryByKey('aov', this.previousAnalytics): 0;
          const days = this.previousAnalytics.filter(p=>p.aov > 0).length;
          prev_aov =  days == 0 ? 0 : prev_aov/days;

          this.previous_aov = this.calculatePercentageChange(this.aov, prev_aov)

    });
    },
  },
  watch: {
    dateFilter: {
      immediate:true,
      handler(newVal){
        if(newVal){
          const nextDay = new Date(newVal[1]);
          nextDay.setDate(newVal[1].getDate() + 1)

          let query = `project=${localStorage.getItem('current_project')}`
          query+= `&dateFrom=${this.formatDate(newVal[0])}&dateTo=${this.formatDate(nextDay)}`;  

          const timeDifference = newVal[1].getTime() - newVal[0].getTime();
          this.periodLength =  Math.trunc(timeDifference / (1000 * 3600 * 24) + 1);

          this.applyFilter(query)
        }
      }
    }
  }
};
</script>

<template>
  <Layout>
    <div class="row mb-4">
      <div class="col-lg-12 mb-3">
        <div class="d-flex align-items-center">
          <div class="ms-3 flex-grow-1 mb-0">
            <h5 class="mb-0 card-title" style="font-size: 17px;">{{$t('personal_analyze.title')}}</h5>
            <p class="text-muted mb-0">{{ $t('personal_analyze.subtitle') }}</p>
            <a class="small text-primary mb-0" href="https://academy.gopersonal.ai/guia-de-usuario/analytics/personalizaciones" target="_blank">{{ $t('common.visit_academy') }} <i class="bx bx-link-external"></i></a>
          </div>
          <div class="text-end w-50">
            <div class="row align-items-center justify-content-end">
              <div class="col d-flex justify-content-end pe-0" v-if="false">
                <ul class="nav nav-pills product-view-nav interact" v-if="!personalizationId" v-on:click="onFilterClicked">
                  <li class="nav-item">
                    <a class="nav-link active" href="javascript: void(0);">
                      <i class="bx bx-filter align-middle"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <div class="col" v-if="false">
                <!--<p class="mb-0 text-start">{{$t('reco_analyze.date_range')}}</p>-->
                <date-picker v-model="dateFilter" :shortcuts="shortcuts" placeholder="Selecioná la fecha" range :format="'DD.MM.YYYY'" :clearable="false" :disabled-date="(date) => date >= new Date() || date < new Date().setDate((new Date()).getDate() - 90)">
                </date-picker>    
              </div>
              <div class="col-auto ps-0 w-50">
                <div class="flex">
                    <date-picker v-model="dateFilter" :shortcuts="shortcuts" placeholder="Selecioná la fecha" range
                      :format="'DD.MM.YYYY'" :clearable="false"
                      :disabled-date="(date) => date >= new Date() || date < new Date().setDate((new Date()).getDate() - 90)">
                    </date-picker>
                </div>
              </div>
              <div class="col-auto ms-0 ps-0">
                <b-dropdown ref="actionsDropdown" ngbDropdown menu-class="dropdown-menu-sm dropdown-menu-end pb-0" right variant="primary">
                    <template v-slot:button-content class="ms-2">
                      <i class="mdi mdi-apps me-1 text-white"></i>
                      <span class="d-none d-sm-inline-block" ngbDropdownToggle>
                        {{$t('common.actions')}}
                        <i class="mdi mdi-chevron-down"></i>
                      </span>
                    </template>
                    
                    <a class="dropdown-item" href="javascript: void(0);" v-on:click="onFilterClicked">
                      <i class="mdi mdi-filter-variant"></i>
                      {{ $t('common.filter') }}
                    </a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="javascript: void(0);" v-on:click="onComparePreviousPeriod">
                      <i class="mdi mdi-compare"></i>
                      {{ $t('common.compare') }}
                    </a>
                    <div class="dropdown-divider mb-0"></div>
                  </b-dropdown>
              </div>
            </div>
          </div>
        </div>
        <div class="badge bg-primary m-1" v-for="f in filters" :key="f.key">
          <div class="d-flex align-items-center justify-content-between">
              <span class="pe-2">{{ f.key!= 'compare' ? f.badge : `${f.badge} (${periodLength} ${periodLength>1 ? $t('common.days_badge') : $t('common.day_badge')})` }}</span>
              <i class="fa fa-times interact " v-on:click="onRemoveFilter(f)"></i>
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-2 text-center" >
                <div>
                    <p class="text-muted text-truncate mb-0 d-inline-flex font-size-13">
                      {{$t('personal_analyze.customers')}}<i class="mdi mdi-help-circle-outline ms-1 text-primary interact" v-b-popover.hover.bottom="$t('personal_analyze.customers_tooltip')"/>
                    </p>
                    <h3 class="mb-0" v-b-popover.hover.bottom="customers">{{ parseCountReduced(customers)}}</h3>
                    <span class="badge badge-soft-success font-size-12" :class="{
                        'badge-soft-warning': previous_customers === 0,
                        'badge-soft-danger': previous_customers < 0,
                        'badge-soft-success': previous_customers > 0,
                        // eslint-disable-next-line vue/no-parsing-error
                        }" v-if="filters.filter(f=>f.key=='compare').length > 0"> {{ `${previous_customers > 0 ? '+'  : '' } ${ previous_customers}%` }}</span>
                  </div>
                </div>

              <div class="col-sm-2 text-center">
                <div>
                    <p class="text-muted text-truncate mb-0 d-inline-flex font-size-13">
                      {{ $t('personal_analyze.sessions') }}<i class="mdi mdi-help-circle-outline ms-1 text-primary interact" v-b-popover.hover.bottom="$t('personal_analyze.sessions_tooltip')"/>
                    </p>
                    <h3 class="mb-0" v-b-popover.hover.bottom="sessions">{{ parseCountReduced(sessions)}}</h3>
                    <span class="badge badge-soft-success font-size-12" :class="{
                        'badge-soft-warning': previous_sessions === 0,
                        'badge-soft-danger': previous_sessions < 0,
                        'badge-soft-success': previous_sessions > 0,
                        // eslint-disable-next-line vue/no-parsing-error
                        }" v-if="filters.filter(f=>f.key=='compare').length > 0"> {{ `${previous_sessions > 0 ? '+'  : '' } ${ previous_sessions}%` }}</span>
                  </div>
              </div>
              <div class="col-sm-2 text-center">
                <div>
                    <p class="text-muted text-truncate mb-0 d-inline-flex font-size-13">
                      {{$t('personal_analyze.impressions')}} <i class="mdi mdi-help-circle-outline ms-1 text-primary interact" v-b-popover.hover.bottom="$t('personal_analyze.impressions_tooltip')"/>
                    </p>
                    <h3 class="mb-0" v-b-popover.hover.bottom="impressions">{{ parseCountReduced(impressions)}}</h3>
                    <span class="badge badge-soft-success font-size-12" :class="{
                        'badge-soft-warning': previous_impressions === 0,
                        'badge-soft-danger': previous_impressions < 0,
                        'badge-soft-success': previous_impressions > 0,
                        // eslint-disable-next-line vue/no-parsing-error
                        }" v-if="filters.filter(f=>f.key=='compare').length > 0"> {{ `${previous_impressions > 0 ? '+'  : '' } ${ previous_impressions}%` }}</span>
                  </div>
              </div>
              
            <div class="col-sm-2 text-center" style="border-right-style: solid; border-right-color: lightgray;">
                <div>
                    <p class="text-muted text-truncate mb-0 d-inline-flex font-size-13">
                      {{$t('personal_analyze.conversion_rate')}}<i class="mdi mdi-help-circle-outline ms-1 text-primary interact" v-b-popover.hover.bottom="$t('personal_analyze.cr_tooltip')"/>
                    </p>
                    <h3 class="mb-0">{{conversion_rate}}</h3>
                    <span class="badge badge-soft-success font-size-12" :class="{
                        'badge-soft-warning': previous_conversion_rate === 0,
                        'badge-soft-danger': previous_conversion_rate < 0,
                        'badge-soft-success': previous_conversion_rate > 0,
                        // eslint-disable-next-line vue/no-parsing-error
                        }" v-if="filters.filter(f=>f.key=='compare').length > 0"> {{ `${previous_conversion_rate > 0 ? '+' : '' } ${previous_conversion_rate}%` }}</span>
                  </div>
              </div>
              <div class="col-sm-2 text-center">
                <div>
                  <p class="text-muted text-truncate mb-0 d-inline-flex font-size-13">
                    {{$t('personal_analyze.revenue')}}<i class="mdi mdi-help-circle-outline ms-1 text-primary interact" v-b-popover.hover.bottom="$t('personal_analyze.revenue_tootltip')"/>
                  </p>
                  <h3 class="mb-0" v-b-popover.hover.bottom="`$${parseMoneyWithLocale(revenue)}`">{{ `$${parseMoneyReducedWithLocale(revenue)}` }}</h3>
                  <span class="badge badge-soft-success font-size-12" :class="{
                        'badge-soft-warning': previous_revenue === 0,
                        'badge-soft-danger': previous_revenue < 0,
                        'badge-soft-success': previous_revenue > 0,
                        // eslint-disable-next-line vue/no-parsing-error
                        }" v-if="filters.filter(f=>f.key=='compare').length > 0"> {{ `${previous_revenue > 0 ? '+' : '' } ${previous_revenue}%` }}</span>
                </div>
            </div>
            <div class="col-sm-2 text-center" >
                <div>
                  <p class="text-muted text-truncate mb-0 d-inline-flex font-size-13">
                    {{'AOV'}}<i class="mdi mdi-help-circle-outline ms-1 text-primary interact" v-b-popover.hover.left="$t('personal_analyze.aov_tooltip')"/>
                  </p>
                  <h3 class="mb-0">{{`$${parseMoneyWithLocale(aov)}`}}</h3>
                  <span class="badge badge-soft-success font-size-12" :class="{
                        'badge-soft-warning': previous_aov === 0,
                        'badge-soft-danger': previous_aov < 0,
                        'badge-soft-success': previous_aov > 0,
                        // eslint-disable-next-line vue/no-parsing-error
                        }" v-if="filters.filter(f=>f.key=='compare').length > 0"> {{ `${previous_aov > 0 ? '+' : '' } ${previous_aov}%` }}</span>
                </div>
            </div>
            <!--<div class="col-sm-2 text-center" >
                <div>
                  <p class="text-muted text-truncate mb-0 d-inline-flex font-size-13">
                    {{$t('personal_analyze.aov')}}<i class="mdi mdi-help-circle-outline ms-1 text-primary interact" v-b-popover.hover.left="$t('personal_analyze.aov_tooltip')"/>
                  </p>
                  <h3 class="mb-0">{{`$${parseMoneyWithLocale(revenue_customer)}`}}</h3>
                  <span class="badge badge-soft-success font-size-12" :class="{
                        'badge-soft-warning': previous_revenue_customer === 0,
                        'badge-soft-danger': previous_revenue_customer < 0,
                        'badge-soft-success': previous_revenue_customer > 0,
                        // eslint-disable-next-line vue/no-parsing-error
                        }" v-if="filters.filter(f=>f.key=='compare').length > 0"> {{ `${previous_revenue_customer > 0 ? '+' : '' } ${previous_revenue_customer}%` }}</span>
                </div>
            </div>-->
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="card">
          <Timeline :query="query" :trendings="personalAnalytics" :compare="filters.filter(f=>f.key=='compare').length > 0" :oldTrendings="previousAnalytics" :periodLength="periodLength" />
        </div>
      </div>
    </div>
    </div>
    <Drawer
      @close="rightDrawer"
      :align="'right'"
      :closeable="true"
      :maskClosable="true"
      :zIndex="1002"
      class="h-100">
          <div v-if="openRightDrawer" class="h-100">
              <div class="offcanvas-header">
                  <h4 class="offcanvas-title" id="offcanvasExampleLabel">
                  <i class="bx bx-gear font-size-16 align-middle me-1"></i>
                  {{$t('filter.title')}}
                  </h4>
              </div>
              <hr class="mt-1" />
              <div class="offcanvas-body flex-grow-1 h-100">
                <div>
                  <FilterC ref="filterRef"></FilterC>
                </div>
                <div class="position-fixed bottom-0 end-0 p-3">
                  <button class="btn btn-secondary me-2" @click="removeAllFilter">{{$t('filter.clean')}}</button>
                  <button class="btn btn-primary me-2" @click="applyCustomFilter">{{$t('filter.apply')}}</button>
                </div>
              </div>
            </div>
    </Drawer>
</Layout>
</template>

<style scoped>
 .top-right-menu {
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 5px;
}

</style>

<style>
.mx-btn-shortcut{
  font-size: 13px !important;
  font-family: var(--bs-body-font-family);
  padding: 0 4px;
  color: var(--bs-primary)
}

.mx-datepicker-sidebar + .mx-datepicker-content {
    margin-left: 150px !important;
    border-left: 1px solid #e8e8e8;
}

.mx-datepicker-sidebar {
  width: 120px;
}

.cell.active{
  background-color: var(--bs-primary) !important;
}

.dropdown-toggle {
  padding-top: 5px !important;
}
</style>
